<template>
  <b-row class="process-one" no-gutters>
    <b-col
      cols="2"
      md="2"
      lg="1"
      class="d-none d-md-flex flex-column align-items-center process-one-left__sidebar max99"
    >
    </b-col>
    <b-col
      cols="2"
      md="2"
      lg="1"
      class="d-flex d-md-none flex-column align-items-center process-one-left__sidebar max99"
    >
    </b-col>

    <b-col class="process-one-right col px-0">
      <b-row style="padding-left: 20px; padding-right: 20px; max-width: 490px;">
        <b-col cols="12">
          <h4 class="base-form__title">SUBMISSION</h4>
        </b-col>
        <b-col cols="12">
          <template v-if="isPromoter">
            <p class="mt-4 base-form__description-text">
              The Promoter portal and all information that is shared with you
              through Aslice is to be kept confidential unless explicit
              permission is given otherwise. This includes screen shots and/or
              sharing of any playlist information publicly. This portal is
              allowing you access to playlist data and statistics generated by
              your events via the DJs booked that report playlists through
              Aslice.
            </p>
            <p class="mt-4 mb-0 base-form__description-text">
              All data, financial details, or documents you give Aslice are
              stored securely, encrypted, and kept private as per our privacy
              policy. Aslice INC and The Aslice Foundation are governed under
              U.S. law.
            </p>
          </template>
          <template v-else>
            <p class="mt-4 base-form__description-text">
              All data, financial details, or documents you give us are stored
              securely, encrypted, and kept private as per our
              <a href="/privacy" target="_blank" class="link-span"
                >privacy policy.</a
              >
              Aslice INC and The Aslice Foundation are governed under U.S. law.
            </p>
            <p class="mt-4 base-form__description-text">
              By downloading Aslice software you give permission to Aslice to
              collect and gather playlist information from your DJ software. By
              checking the box and pressing the submit button you agree to allow
              Aslice to withdraw money and distribute funds on your behalf.
            </p>
            <p class="mt-4 mb-0 base-form__description-text">
              DJ’s submitting playlist - By uploading playlist(s) you agree to
              public view of playlist info. We recognize the request for secrecy
              and have created privacy options in your preferences. *this is in
              line with the Aslice mission of supporting artists and
              simultaneously keeping your “secret tracks” out of view”.
            </p>
            <p class="mt-4 mb-0 base-form__description-text">
              By registering with Aslice, you acknowledge your understanding and
              agreement to the terms spelled out in Aslice’s Terms page and to
              only share correct and un-falsified data; this includes (but is
              not limited to) your artist information, accurate playlists, and
              music metadata.
            </p>
          </template>
        </b-col>

        <b-col
          cols="12"
          class="mt-4"
          :class="
            !!status ? 'verify-process__badge active' : 'verify-process__badge'
          "
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model.trim="status"
            name="checkbox-1"
          >
            <span style="position: relative; top: -2px; margin-left: 10px"
              >I agree</span
            >
          </b-form-checkbox>
          <!-- <div class="error" v-if="isDisabled">
            Field is required.
          </div> -->
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background-color: #AAA3B1; height: 1px; margin-left: 0px"
      ></b-row>

      <b-row style="padding-left: 20px; padding-right: 20px; max-width: 490px;">
        <b-col cols="12" class="mt-4">
          <ButtonFill :disabled="!status" @click="go">
            Submit
          </ButtonFill>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VerifyProcessController from "@/mixins/verify-process-controller";
import swal from "sweetalert2";
import ButtonFill from "@/components/global/buttons/ButtonFill.vue";

export default {
  name: "Process-5",
  components: {
    ButtonFill
  },
  mixins: [VerifyProcessController],
  data() {
    return {
      status: false,
      isDisabled: true,
      dirty: false
    };
  },
  async mounted() {
    localStorage.removeItem("redirectedFromProccess");
    if (!localStorage.getItem("verificationStarted")) {
      localStorage.setItem("verificationStarted", 5);
    }
    if (
      localStorage.getItem("verificationStarted") &&
      localStorage.getItem("verificationStarted") < 5
    ) {
      localStorage.removeItem("verificationStarted");
      localStorage.setItem("verificationStarted", 5);
    }
    await this.$nextTick();
    window.scrollTo(0, 0);
  },
  watch: {
    status() {
      this.isDisabled = !this.status;
      this.dirty = !this.status;
    }
  },
  computed: {
    ...mapState("verifySteps", ["registrationForm"]),
    ...mapState("user", ["data"]),
    ...mapGetters("user", ["isPromoter"])
  },
  methods: {
    ...mapActions("user", ["updateUser"]),
    async go() {
      if (!this.status) {
        swal.fire({
          icon: "error",
          text: "You need to agree"
        });
        return;
      }
      if (this.status) {
        localStorage.removeItem("verifyUserId");
        localStorage.removeItem("tempEmail");
        localStorage.removeItem("tempId");
        localStorage.removeItem("verificationStarted");
        await this.updateUser({
          newData: {
            licenseAgreementAccepted: true
          },
          user: this.user
        });
        this.$store.dispatch("user/setCurrentTabComponent", 6);
      }
    },
    goBack(val) {
      this.$store.dispatch("user/setCurrentTabComponent", val);
    }
  }
};
</script>
<style lang="scss" scoped>
.max99 {
  max-width: 99px;
  min-width: 99px;
}
@media screen and (max-width: 768px) {
  .max99 {
    max-width: 65px;
    min-width: 65px;
  }
}

.link-span {
  color: #442671;
  text-decoration: underline;
  &:hover,
  &:active,
  &:visited {
    color: #442671;
  }
}

::v-deep .custom-control-label {
  &:after {
    top: 7px;
    left: -17px;
  }
}
::v-deep .vs__selected-options {
  padding: 0 20px !important;
}
::v-deep .vs__dropdown-toggle {
  min-height: 30px;
}
</style>
