<template>
  <div class="sidebar" :class="{ 'sidebar--promoter': isPromoter }">
    <div class="d-flex flex-column justify-content-between h-100">
      <div style="cursor: pointer;" @click="checkAndRoute">
        <svg
          width="80"
          height="20"
          viewBox="0 0 80 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.35286 2.51932C4.61904 2.51932 0.769043 6.43856 0.769043 11.2575C0.769043 13.2588 1.43298 15.1066 2.54961 16.5812L3.34721 15.7693C2.4289 14.5053 1.88567 12.9428 1.88567 11.2575C1.88567 7.06617 5.23556 3.65164 9.35717 3.65164C13.4788 3.65164 16.8287 7.06178 16.8287 11.2575C16.8287 12.9472 16.2854 14.5053 15.3671 15.7693L16.1647 16.5812C17.2814 15.1066 17.9453 13.2588 17.9453 11.2575C17.941 6.43856 14.091 2.51932 9.35286 2.51932ZM13.3839 13.7548C13.8323 13.0087 14.0694 12.1485 14.0694 11.2575C14.0694 8.61105 11.9526 6.45612 9.35286 6.45612C6.75314 6.45612 4.6406 8.61105 4.6406 11.2575C4.6406 12.1485 4.88203 13.0087 5.3261 13.7548L6.14525 12.9209C5.89088 12.4118 5.75292 11.8412 5.75292 11.2575C5.75292 9.23426 7.36966 7.59283 9.35286 7.59283C11.3361 7.59283 12.9528 9.23865 12.9528 11.2575C12.9528 11.8412 12.8192 12.4118 12.5605 12.9209L13.3839 13.7548ZM9.35286 10.4544L3.69642 16.2169L2.90745 17.0201C3.1532 17.3054 3.41619 17.5731 3.69642 17.8233C5.20969 19.175 7.18858 20.0001 9.35717 20.0001C11.5258 20.0001 13.5047 19.1794 15.0179 17.8233C15.2982 17.5731 15.5611 17.3054 15.8069 17.0201L15.0179 16.2169L9.35286 10.4544ZM9.35286 18.8634C7.49469 18.8634 5.79172 18.17 4.48108 17.0201L9.35286 12.0607L14.2246 17.0201C12.9183 18.17 11.2153 18.8634 9.35286 18.8634Z"
            fill="white"
          />
          <path
            d="M32.9658 5.27112H34.8714V17.1649H32.9658L32.7804 15.6244C31.7845 16.6997 30.4178 17.3756 28.8873 17.3756C25.5331 17.3756 22.8989 14.6764 22.8989 11.218C22.8989 7.7596 25.5288 5.06046 28.8873 5.06046C30.4222 5.06046 31.7888 5.73634 32.7804 6.83355L32.9658 5.27112ZM32.802 11.218C32.802 8.9402 31.1465 7.21099 28.9477 7.21099C26.7317 7.21099 25.0718 8.9402 25.0718 11.218C25.0718 13.4958 26.7274 15.225 28.9477 15.225C31.1465 15.225 32.802 13.4958 32.802 11.218Z"
            fill="white"
          />
          <path
            d="M36.1605 15.8395L37.2167 14.0664C38.273 14.8695 39.6009 15.3304 40.7606 15.3304C42.3989 15.3304 43.2052 14.909 43.2052 13.8338C43.2052 12.9472 42.4809 12.4425 40.8037 12.1265C37.9238 11.556 36.7037 10.4587 36.7037 8.45743C36.7037 6.39028 38.4023 5.06046 41.0754 5.06046C42.5067 5.06046 43.8734 5.42034 44.9512 6.07428L43.8734 7.84738C42.981 7.32071 42.1963 7.11005 41.0754 7.11005C39.7087 7.11005 38.8378 7.61477 38.8378 8.46181C38.8378 9.34836 39.3551 9.6863 41.3642 10.1296C44.0588 10.7396 45.3608 11.9202 45.3608 13.8425C45.3608 16.0545 43.5587 17.3843 40.7606 17.3843C39.0577 17.3756 37.2771 16.7875 36.1605 15.8395Z"
            fill="white"
          />
          <path
            d="M46.8698 13.8996V0.00012207H49.0254V13.6012C49.0254 14.7203 49.2755 15.3523 50.2283 15.3523C50.6422 15.3523 50.9526 15.225 51.388 14.9749L52.177 16.7875C51.4915 17.2308 50.7672 17.38 50.0429 17.38C47.9864 17.3756 46.8698 16.0282 46.8698 13.8996Z"
            fill="white"
          />
          <path
            d="M53.022 1.89613C53.022 1.09297 53.6644 0.439026 54.4706 0.439026C55.2595 0.439026 55.9192 1.09297 55.9192 1.89613C55.9192 2.69929 55.2552 3.35322 54.4706 3.35322C53.6644 3.35322 53.022 2.69929 53.022 1.89613ZM53.3755 17.1649V5.27115H55.5312V17.1649H53.3755Z"
            fill="white"
          />
          <path
            d="M57.1867 11.218C57.1867 7.7596 59.9201 5.06046 63.3605 5.06046C65.016 5.06046 66.4043 5.65295 67.4605 6.68433L66.0119 8.26432C65.3092 7.63232 64.3736 7.21099 63.3605 7.21099C61.1229 7.21099 59.3639 8.9402 59.3639 11.218C59.3639 13.4958 61.1229 15.225 63.3605 15.225C64.3736 15.225 65.3092 14.8037 66.0119 14.1717L67.4821 15.7517C66.4043 16.7655 65.016 17.3756 63.3605 17.3756C59.9201 17.3756 57.1867 14.6764 57.1867 11.218Z"
            fill="white"
          />
          <path
            d="M79.1874 11.7886H70.0302C70.2587 13.8777 71.8754 15.2909 74.113 15.2909C75.3159 15.2909 76.4756 14.8257 77.2215 14.0664L78.5881 15.6069C77.45 16.7875 75.9367 17.38 74.0699 17.38C70.4872 17.38 67.8961 14.83 67.8961 11.3058C67.8961 7.80349 70.5045 5.06485 73.8414 5.06485C76.9714 5.06485 79.2262 7.36461 79.2262 10.5465C79.2305 11.4506 79.2305 11.5999 79.1874 11.7886ZM77.0964 9.95403C77.0749 8.3916 75.6478 7.14956 73.8242 7.14956C72.0824 7.14956 70.6769 8.26871 70.1984 9.95403H77.0964Z"
            fill="white"
          />
        </svg>
      </div>
      <!-- <ol>
        <li>
          <router-link to="/contact" class="text-upppercase"
            >Contact</router-link
          >
        </li>
      </ol> -->
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["isPromoter"])
  },
  methods: {
    checkAndRoute() {
      if (localStorage.getItem("verificationStarted")) {
        if (localStorage.getItem("verificationStarted") < 6) {
          swal.fire({
            icon: "error",
            text: "You need to finish verification process"
          });
        }
      } else {
        this.$router
          .push({
            name: "Main"
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style scoped>
img {
  max-height: 25px;
}
.text-upppercase {
  font-family: "DMSans";
  font-size: 13px;
  letter-spacing: 4px;
}
</style>
